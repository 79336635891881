.wrapper{
    padding: 40px;
    border: 3px solid #61dafb ;
    border-radius: 20px;
    width: 90%;
    max-width: 400px;
    margin: 40px auto;
}
.point{
    margin:  10px;
    border: 3px solid #61dafb;
    background-color: #f7f9ff;
    border-radius: 10px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
}
