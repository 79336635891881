.App {
  text-align: center;
}

select{
  width: 180px;
  font-size: 26px;
  text-align: center;
}

.btn{
  cursor: pointer;
  border: 3px solid #61dafb;
  height: 30px;
  line-height: 30px;
  width: 180px;
  margin: 20px auto;
  border-radius: 20px;

}