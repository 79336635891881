.wrapper{
    padding: 10px 5px;
    border: 3px solid #61dafb;
    max-width: 250px;
    margin: 10px auto;
}

.input_box{
    display: block;
    margin: 10px auto;
    border:3px solid #61dafb;
    padding: 5px 10px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    border-radius: 10px;
}