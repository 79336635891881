

.status_game_stop,
.status_game_start{
    margin-bottom: 10px;
}

.status_game_start{
    background-color: rgba(0, 137, 54, 0.42);
}
.status_game_stop{
    background-color: rgba(255, 3, 3, 0.42);
}

.table_team_task,
.table_team_tasks,
.table_team_map{
    margin: 10px auto;
    border-collapse: collapse;
}
.table_team_tasks th,
.table_team_map th {
    font-weight: bold;
    padding: 10px;
    background: #00C8C1;
    color:#fff;
    font-weight: 400;
}
.table_team_tasks td,
.table_team_map td {
    padding:10px;
    background: #00c8c11c;
}

.table_team_task{
    width: 100%;
    margin: auto;
}

.title_score{
    font-weight: 700;
    margin-top: 20px;
}

.wrapper_score{
    background-color: rgba(205, 205, 205, 0.51);
}

.table_team_task th{
    background-color: #acfcf6;
    color: black;
    padding: 5px;
}

.is_buy_yes{
    color: #036a2d;
    font-weight: 600;
}
.is_buy_no{
    color: #840000;
    font-weight: 600;
}