.wrapper{

}

.input_box{
    display: block;
    margin: 10px auto;
    border:3px solid #61dafb;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
}

.table_additional_resource{
    display: inline-block;
    margin: auto;
    padding: 10px;
    border: 3px solid #61dafb;

}

.title_field{
    margin: 20px auto 10px auto;
}