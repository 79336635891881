
.wrapper{
    display: inline-block;

    /*border: 3px solid #61dafb;*/
    margin: 20px;
}

.row_task{
    padding: 3px;
    display: block;

    margin: 2px;
}

.btn_del{
    border: 3px solid #61dafb;
    margin-top: 3px;
    cursor:pointer;
}
.select_map_task{
    font-size: 18px;
    margin: 2px;
    width: 350px;
}