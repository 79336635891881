.wrapper{
    width: 280px;
    margin: 30px auto;
    border:3px solid #61dafb;
    border-radius: 20px;
}

.btn_continue{
    width: 100px;
    border:3px solid #61dafb;
    height: 30px;
    line-height: 30px;
    border-radius: 10px;
    margin: 10px auto;
    cursor: pointer;
}