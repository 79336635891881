.wrapper{
    background-color: #f6f6f6;
    padding: 10px;
    margin:10px auto;
}


.title_table_map{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.table_task,
.table_map {
    width: 90%;
    border: 1px solid #dddddd;
    border-collapse: collapse;

}

.table_map th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
}
.table_map td {
    border: 1px solid #dddddd;
    padding: 5px;
}

.table_task{
    width: 100%;
}

.team_name{
    font-weight: 800;
}