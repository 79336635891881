.activePoint,
.point{
    display: inline-block;
    margin: 10px 2px;
    border: 3px solid #61dafb;
    padding: 5px;
    cursor: pointer;

}

.activePoint{
    background-color: rgba(97, 218, 251, 0.42);
}