.btn_back{
    width: 220px;
    border:3px solid #61dafb;
    height: 30px;
    line-height: 30px;
    border-radius: 10px;
    margin: 10px auto;
    cursor: pointer;
}


.game_status_start{
    background: #aef8ae;
}

.game_status_stop{
    background: #fdc8c8;
}

.game_finish{
    display: inline-block;
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    color:white;
    background: #008936;
}

.round{
    margin-bottom: 10px;
}

.title_key_words{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.table_key_words {
    width: 90%;
    border: 1px solid #dddddd;
    border-collapse: collapse;
    margin: auto;
}

.table_key_words th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
}
.table_key_words td {
    border: 1px solid #dddddd;
    padding: 5px;
}