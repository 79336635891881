.wrapper{
    background-color: #f6f6f6;
    padding: 10px;
}

.title_table_score{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.table_score {
    width: 90%;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    border-collapse: collapse;
    margin: 0 auto 20px auto;
}
.table_score th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
}
.table_score td {
    border: 1px solid #dddddd;
    padding: 5px;
}

.is_buy_yes{
    background-color: #a7fdc8;
}
is_buy_no{
    background-color: #ffb9b9;
}