.wrapper{

}

.input_box{
    display: block;
    margin: 10px auto;
    border:3px solid #61dafb;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
}