.wrapper{
    width: 100%;
    height: 50px;
    background-color: #61dafb;
}

.logo{
    float:left;
    vertical-align: center;
    padding: 10px;
    color: #4e4e4e;
}

.point{
    float: right;
    vertical-align: center;
    padding: 10px;
    color: #4e4e4e;

}